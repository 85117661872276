<template>
    <v-row class="h-100">
        <background page="subscriptions" v-if="!loading && checkIns.length > 0"></background>

        <!-- esto es para probar los checkins de otros clientes -->
        <!-- <v-btn block depressed color="primary" @click="checkIn">Check-in</v-btn> -->

        <v-col v-if="!loading" class="h-100">
            <v-row v-if="checkIns.length > 0">
                <v-col cols="12">
                    <v-card flat class="rounded-xl shadow" v-if="!loading && checkIns.length > 0">
                        <v-card-title class="justify-center petu-green">Check-Ins</v-card-title>
                        <v-card-text>
                            <v-list two-line>
                                <template v-for="(cI, index) in checkIns">
                                    <v-list-item :key="cI.id" class="px-0">
                                        <v-list-item-content>
                                            <v-list-item-title>{{ cI.session.name }}</v-list-item-title>
                                            <v-list-item-subtitle>
                                                <span class="secondary--text">{{ cI.session.type }}</span> | {{ formatDate( cI.checkinDate, 'YYYY-MM-DD @ hh:mm a' ) }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider v-if="index < checkIns.length - 1" :key="index"></v-divider>
                                </template>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-else class="h-100" align="center" color="blue" style="margin-top: -75px">
                <v-col class="text-caption">
                    <v-card class="rounded-xl">
                        <v-card-text class="text-center">
                            <v-icon size="75" class="petu-orange mb-3">mdi-alert-circle</v-icon>
                            <div>
                                <span>{{ alertText }}</span>
                                <span v-if="!isLoggedIn" class="ml-1"><router-link to="/login" class="link">aquí</router-link></span>
                            </div>
                        </v-card-text>
                    </v-card>
                 </v-col>
            </v-row>
        </v-col>

        <!-- TODO: make component -->
        <v-dialog v-if="isLoggedIn && checkIns.length > 0" v-model="loading" hide-overlay persistent width="300">
            <v-card class="petu-green-bg" dark>
                <v-card-text> 
                    <span>Cargando check-ins</span>
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import { PetuCustomer } from '@/classes/customer'
import { _st } from '@/softech'
import moment from 'moment'

import Background from '@/components/Background'

export default {
	name: 'CheckIns',
    components: { Background },
    data:() => ({
        checkIns: [],
        sessions: [],
        resource: {},
        loading: true,

        showResource: false,
        selectedResource: {},
        selectedProductId: '',
    }),
    async mounted() {
        if (this.isLoggedIn) {
            let customer = new PetuCustomer( this.customer );
            this.checkIns  = await customer.getCheckIns();
        }

        this.loading = false;
    },
    methods: {
        formatDate( d, format ) {
            return moment(d).format(format);
        },
    },
    computed: {
        ...mapGetters({ 
            customer    : 'auth/customer', 
            isLoggedIn  : 'auth/isLoggedIn' 
        }),
        alertText() { 
            if(this.isLoggedIn) {
                return 'Actualmente no tiene check-ins'
            } else {
                return 'Para poder ver sus check-ins, ingrese a su cuenta'
            }
        },
    }
}
</script>

<style scoped>
.shadow {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px !important;
}
</style>